import {
  useState,
} from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from '@mui/material';
import {
  GoogleMap,
  useLoadScript,
  MarkerF
} from '@react-google-maps/api';
import {
  Helmet,
} from 'react-helmet-async';
import {
  LockClock,
  AttachMoney,
  ShoppingBag,
  ProductionQuantityLimits,
  Favorite,
  AddCard,
} from '@mui/icons-material';
import {
  useNavigate,
} from 'react-router-dom';
import {
  StackRowList,
  Title,
  Seo,
} from '../components/ui';
import {
  CategoryModal,
} from '../components/category';
import {
  env,
  color,
} from '../env';

const TopHome = () => {
  const [showCategory, setShowCategory] = useState(false),
    push = useNavigate();

  return (
    <StackRowList
      width={{
        xs: '100%',
        md: '90%',
      }}
      justifyContent='center'
      flexDirection='column'
      sx={{
        backgroundColor: color.bgcolor,
      }}>
      <Seo
        title='Jajan Pasar Palembang'
        description='Jajanan Pasar sehat di Palembang, Fresh Everyday... Terima pesan online'
        link={{
          href: '/',
          rel: 'canonical',
        }}/>
      {showCategory
        ? <CategoryModal
            open
            onSelect={category => push(`/product?order_by=stock&sort_by=desc&category=${category === 'all' ? '' : category}`)}
            onClose={setShowCategory}/>
        : null}
      <StackRowList
        justifyContent={{
          xs: 'center',
          md: 'flex-start',
        }}
        width={{
          md: 280,
          xs: 200,
        }}>
        <img
          src='/images/elona.png'
          loading='lazy'
          alt='elona'
          style={{height: 'auto', width: '100%'}}/>
      </StackRowList>

      <StackRowList
        sx={{
          mt: 1,
        }}>
        <Button
          variant='contained'
          size='small'
          sx={{
            width: {
              xs: 150,
              md: 200,
            },
            backgroundColor: color.mainColor,
            m: 1,
          }}
          onClick={_ => setShowCategory(true)}>
          {/* onClick={_ => null}> */}
          Kategori
        </Button>

        <StackRowList
          width='auto'
          alignItems='center'
          sx={{mr: 1}}
          justifyContent='center'>
          <LockClock color='info' sx={{mr: 1}}/>
          <Typography
            sx={{
              fontSize: {
                xs: 10,
                md: 17,
              },
            }}
            variant='caption'>
            Buka Senin - Sabtu Pukul 11.30 s/d 17.30 WIB
            {/* COMMING SOON */}
          </Typography>
        </StackRowList>
      </StackRowList>
    </StackRowList>
  )
}

const Maps = () => {
  const [libraries] = useState(['places']),
    { isLoaded, loadError } = useLoadScript({
      googleMapsApiKey: `${env.googleApiKey}&loading=async`,
      version: '3.55',
      libraries,
    }),
      [center] = useState({
        lat: -2.9826114, // default latitude
        lng: 104.7496149, // default longitude
      });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  return (
    <GoogleMap
      mapContainerStyle={{
        width: '100%',
        height: '100%',
      }}
      zoom={20}
      center={center}
    >
      <MarkerF
        position={center}
        icon='https://maps.google.com/mapfiles/ms/icons/pink-dot.png'/>
    </GoogleMap>
  );
};

const HomePage = () => {
  const push = useNavigate()

  return (

    <StackRowList
      alignItems='center'
      justifyContent='flex-start'
      flexDirection='column'>
      {/* // TOP */}
      <TopHome/>
  
      {/* MIDDLE */}
      <StackRowList
        width={{
          md: '85%',
          xs: '95%',
        }}
        justifyContent='center'
        flexDirection='column'
        sx={{
          backgroundColor: color.bgcolor,
          p: {
            md: 2,
            xs: 0.5,
          }
        }}>
        <Title sx={{mt: 1.5, color: '#F48C8D'}} label='Jajanan Pasar & Bakery'/>
        <Divider sx={{width: '50%', mt: 1, mb: 1}}/>
        <Typography variant='subtitle2' fontSize={17} textAlign='center'>
          Selamat datang di Jajan Elona & Bakery<br/>
          Soon Opening
        </Typography>

        <Title
          sx={{mt: 3.5, color: '#F48C8D'}}
          label='Keunggulan Kami'/>
        <Divider sx={{width: '50%', mt: 1, mb: 1}}/>


        <Helmet>
          <meta
            name='description'
            content='Keunggulan Jajan Elona & Bakery'/>
          <link
            rel='canonical'
            href='/'/>
        </Helmet>
        {[
          {icon: <Favorite fontSize='large' color='primary' sx={{fontWeight: 'bolder'}}/>, title: 'Bahan Terbaik & Tanpa Pengawet', subtitle: 'Semua produk kami menggunakan bahan terbaik & bebas dari berbagai macam pengawet makanan'},
          {icon: <AttachMoney fontSize='large' color='primary' sx={{fontWeight: 'bolder'}}/>,title: 'Harga Bersaing', subtitle: 'Harga siap bersaing di pasaran'},
          {icon: <ProductionQuantityLimits fontSize='large' color='primary' sx={{fontWeight: 'bolder'}}/>, title: 'Pantau Stock', subtitle: 'Lihat stock produk kami secara online', button: <>
            <Button size='small' variant='outlined' onClick={_ => push('/product')}>Lihat Produk</Button>
          </>},
          {icon: <AddCard fontSize='large' color='primary' sx={{fontWeight: 'bolder'}}/>,title: 'Point Belanja', subtitle: 'Belanja dapat Point hanya untuk member Jajan Elona'},
          {icon: <ShoppingBag fontSize='large' color='primary' sx={{fontWeight: 'bolder'}}/>,title: 'Pesanan Online', subtitle: 'Melayani pesanan Online via Whatsapp / (Website *Priority*)', button: <>
            <Button size='small' variant='outlined' href='https://api.whatsapp.com/send/?phone=6289625091994&text=Hallo+Admin' target='_blank'>Via Whatsapp</Button>
            <Button size='small' variant='outlined' href='https://my.jajanelona.com/all' target='_blank'>Via Website *priority*</Button>
          </>},
        ].map(view => (
          <Card
            key={view.title}
            sx={{
              mt: 1,
              width: {
                xs: '80%',
                md: '50%',
              },
            }}>
            <CardContent>
              {view.icon}
              <Typography
                gutterBottom
                fontWeight='bolder'
                variant='h5'
                component='div'>
                {view.title}
              </Typography>
              <Typography variant='caption'>
                {view.subtitle}
              </Typography>
            </CardContent>
            {view.button
              ? <CardActions>
                  {view.button}
                </CardActions>
              : null}
          </Card>
        ))}
        
      </StackRowList>

      <StackRowList
        sx={{
          pt: 1,
          width: {
            xs: '100%',
            md: '90%',
          },
          height: '35vh',
          backgroundColor: color.bgcolor
        }}
        justifyContent='center'>
        <Maps/>

      </StackRowList>
  
      {/* // BEST SELLER */}
  
    </StackRowList>
  )
}

export default HomePage;