import {
  useState,
} from 'react';
import {
  Stack,
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  MenuItem,
  Button,
} from '@mui/material';
import {
  useNavigate,
} from 'react-router-dom';
import {
  Menu as MenuIcon,
} from '@mui/icons-material';
import {
  Title,
} from './ui';

const pages = [
  // {value: 'product', view: 'Produk'},
  {value: 'parent', view: 'My Parent'},
];

function HeaderAppBar() {
  const [anchorElNav, setAnchorElNav] = useState(null),
    push = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (value) => {
    setAnchorElNav(null);

    if(value) {
      let a = document.createElement("a");
      if(value !== 'parent')
        return;
      a.href = "https://molasweetjar.com";
      a.target = "_blank";
      a.click();
    }
  };

  return (
    <AppBar position="fixed" color='default'>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Stack sx={{
            flexGrow: 1,
          }}>
            <Title
              label='Jajan Elona'
              sx={{
                fontSize: {
                  xs: 15,
                  md: 18,
                },
                color: 'blue',
              }}
              onClick={_ => push('/')}/>
          </Stack>

          

          <Box sx={{display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.view} onClick={_ => handleCloseNavMenu(page.value)}>
                  <Typography textAlign="center">{page.view}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.view}
                onClick={_ => handleCloseNavMenu(page.value)}
                sx={{
                  my: 2,
                  color: 'black',
                  display: 'block',
                  fontWeight: 'bolder',
                  letterSpacing: '.1rem',
                }}
              >
                {page.view}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default HeaderAppBar;
