import {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';
import {
  Alert,
  Button,
  LinearProgress,
  Typography,
} from '@mui/material';
import {
  ArrowLeft,
} from '@mui/icons-material';
import {
  useNavigate,
} from 'react-router-dom';
import {
  ElonaContainer,
  StackRowList,
  SearchInput,
  Seo,
  SetOrder,
} from '../../components/ui';
import {
  MediaProductCard,
} from '../../components/product';
import {
  CategoryModal,
} from '../../components/category';
import {
  useListProducts,
} from '../../services/product';
import {
  convertDataToQuery,
} from '../../helpers/custom';
import {
  color,
} from '../../env';

const ProductPage = () => {
  const push = useNavigate(),
    {data, error, onProcess, query, loading, setOffset, hasMore} = useListProducts(),
    observed = useRef(),
    [placeOrder, setPlaceOrder] = useState(null),
    lastItemFetchRef = useCallback(onHandleCallbackRefFetch, [loading, hasMore, setOffset]),
    [showCategory, setShowCategory] = useState(false);


	function onHandleCallbackRefFetch (node) {
		if(loading) return;
		if(observed.current) observed.current.disconnect();
		observed.current = new IntersectionObserver(async entries => {
			if(entries[0].isIntersecting) {
				if(hasMore)
          setOffset(prev => prev + 1);
			}
		})

		if(node) observed.current.observe(node);
	}


  useEffect(() => {
    onProcess();
  }, [onProcess])

  return (
    <ElonaContainer>
      {placeOrder
        ? <SetOrder
            onClose={_ => setPlaceOrder(null)}
            product={placeOrder}/>
        : null}
      {showCategory
        ? <CategoryModal
            open
            onSelect={category => {
              push(`/product?order_by=stock&sort_by=desc&category=${category === 'all' ? '' : category}`);
              setShowCategory(false);
            }}
            onClose={setShowCategory}/>
        : null}

      <Seo
        title={`${query?.category ? `Menu ${decodeURIComponent(query.category)}` : 'Semua Menu'}`}
        description='Menu jajanan Pasar di Elona, banyak variasi silahkan dicek ya.'/>
      <StackRowList
        sx={{
          mt: 1,
          mb: 1.5
        }}>
        <Button
          variant='outlined'
          size='small'
          onClick={_ => push('/')}
          startIcon={<ArrowLeft/>}>
          Kembali
        </Button>

        <Button
          variant='contained'
          size='small'
          // onClick={_ => null}
          onClick={_ => setShowCategory(true)}
          sx={{
            bgcolor: color.mainColor,
            color: 'white',
          }}>
          {query?.category ? decodeURIComponent(query.category) : 'Kategory'}
        </Button>
      </StackRowList>

      <Typography
        variant='caption'
        color='red'>nb: Stock yang ditampilkan adalah estimati Stock yang tersedia hari ini</Typography>

      <SearchInput
        sx={{
          mt: 1,
          mb: 2,
          width: {
            xs: '95%',
            md: '65%',
          }
        }}
        changeState={_ => null}
        title='Cari Produk'
        valueState=''/>

      <StackRowList
        sx={{
          mt: 1,
        }}
        direction='row'
        justifyContent='center'
        alignItems='flex-start'
        flexWrap='wrap'
        width='100%'>
        {data?.products?.length > 0
          ? data.products.map((product, index) => <Fragment key={product._id || index}>

            <MediaProductCard
              product={product}
              onOrder={_ => setPlaceOrder(product)}
              onPush={url => push(url, {state: {url: `/product${convertDataToQuery(query)}`}})}/>
            {index === data.products.length - 1 && data.count > (query?.limit || 10)
              ? <div ref={lastItemFetchRef}/>
              : null}
          </Fragment>)
          : error
            ? <Alert severity='error'>{error}</Alert>
            : <Alert severity='warning'>Belum ada Produk</Alert>}

        {loading
          ? <LinearProgress sx={{width: '100%', mb: 2, height: 7}}/>
          : null}
      </StackRowList>
    </ElonaContainer>
  )
}

export default ProductPage;