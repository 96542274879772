import {
  useState,
  useCallback,
  useMemo,
} from 'react';
import x from 'axios';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {gtw} from './apis';
import {
  handleError,
  useCancelRequest,
} from './helpers';
import {convertDataToQuery, getQueryUrl} from '../helpers/custom';

const products = '/products',
  categories = '/categories';


export const useListProducts = () => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    {search} = useLocation(),
    push = useNavigate(),
    [error, setError] = useState(''),
    [offset, setOffset] = useState(0),
    [data, setData] = useState({count: 0, products: []}),
    query = useMemo(() => {
      if(!search)
        return {search: ''};
      const _query = getQueryUrl(search);
      if(_query.start && _query.start ==='0')
        setOffset(0);
      delete _query.start;
      return _query;
    }, [search]),
    changeState = payload => {
      setOffset(0);
      push(`/${convertDataToQuery({...query, ...payload})}`)
    },
    onProcess = useCallback(async function () {
      setLoading(true);
      setError('');
      const cancel = await (async function () {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          let _query = {
            order_by: 'createdAt',
            sort_by: 'desc',
            limit: 10,
            ...query,
            offset,
          }
          const {data: _data} = await gtw({
            method: 'get',
            url: `${products}${convertDataToQuery(_query)}`,
            cancelToken: _req.token,
          });
          if(offset === 0)
            return setData(_data);
          setData(prev => ({
            ...prev,
            products: [...prev.products, ..._data.products || []],
          }));
        }catch(e) {
          const message = handleError(e);
          if(message.cancel)
            return true;

          setError(message);
        }
      })();

      if(cancel)
        return;
      setLoading(false);
    }, [offset, query]),
    hasMore = useMemo(() => {
      if(data && data.count) {
        const countMore = Math.floor(data.count / 10);
        return  parseInt(offset, 10) < countMore;
      }
      return false;
    }, [offset, data])

  useCancelRequest(request);

  return {error, loading, hasMore, offset, setOffset, onProcess, data, query, changeState};
}


export const useGetProductId = () => {
  const [loading, setLoading] = useState(false),
    [res, setRes] = useState({status: '', message: ''}),
    [data, setData] = useState(null),
    {product_id} = useParams(),
    [request, setRequest] = useState(null),
    onProcess = useCallback(async function () {
      setRes({status: '', message: ''});
      setLoading(true);
      const cancel = await (async function () {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          const {data: _data} = await gtw({
            method: 'get',
            url: `${products}/${product_id}`,
            cancelToken: _req.token,
          })
          setData(_data);
        }catch(e) {
          const message = handleError(e);
          if(message.cancel)
            return true;
          setRes({status: 'error', message});
        }
      })()

      if(cancel)
        return;
      setLoading(false)
    }, [product_id])

  useCancelRequest(request);

  return {onProcess, loading, res, data, product_id}
}


export const useListCategories = _ => {
  const [loading, setLoading] = useState(false),
    [request, setRequest] = useState(null),
    [error, setError] = useState(''),
    [data, setData] = useState({categories: [], count: 0}),
    onProcess = useCallback(async function () {
      setLoading(true);
      const cancel = await (async function () {
        try {
          const _req = x.CancelToken.source();
          setRequest(_req);
          const {data: _data} = await gtw({
            method: 'get',
            url: `${categories}/0/50`,
            cancelToken: _req.token,
          });
          setData(_data);
        }catch(e) {
          const message = handleError(e);
          if(message.cancel)
            return true;
          setError(message);
        }
      })();
      if(cancel)
        return;
      setLoading(false);
    }, []);

  useCancelRequest(request);

  return {onProcess, loading, error, data}
}