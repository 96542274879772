import {
  useMemo,
} from 'react';
import {
  Typography,
  CardContent,
  CardActions,
  Card,
  CardMedia,
  Button,
  CardHeader,
  Avatar,
} from '@mui/material';
import {
  red,
} from '@mui/material/colors';
import {
  ShoppingCart,
} from '@mui/icons-material';
import {
  convertPrice,
} from '../helpers/custom';



export const MediaProductCard = ({
  onOrder = product_id => null,
  onPush = url => null,
  product = {},
}) => {
  const stock = useMemo(() => {
    let _s = product.stock || 0,
      maxStock = 0;
    if(product.is_variation && product.variations.length > 0) {
      const variantStocks = product.variations.map(el => el.stock).sort((a,b) => a-b);
      _s = variantStocks[0];
      maxStock = variantStocks[variantStocks.length - 1];
    }
    return product.is_variation && product.variations.length > 0
      ? `${_s} - ${maxStock}`
      : _s === 0 ? 'Habis' : _s;
  }, [product]);

  return (
    <Card sx={{width: {xs: '45%', md: '25%'}, m: 1}}>
      <CardHeader
        avatar={
          <Avatar sx={{
            bgcolor: red[500],
            display: {
              xs: 'none',
              md: 'flex'
            }
          }} aria-label="recipe">
            {product?.name[0]?.toUpperCase() || 'A'}
          </Avatar>
        }
        title={product?.name || '-'}
        titleTypographyProps={{
          textTransform: 'uppercase',
          fontWeight: 'bolder',
        }}
        subheader={
          product.promo && product.promo._id && product.promo.is_active
            ? `Promo ${product?.promo?.name || '-'}`
            : ''
        }
        subheaderTypographyProps={{
          color: 'green',
          fontWeight: 'bolder',
          variant: 'caption',
        }}
      />
      <CardMedia
        sx={{height: {xs: 140, md: 200}}}
        image={product.images?.length > 0 ? product.images[0] : '/images/defaultImages.jpeg'}
        title={product.name}
      />
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          ada {product?.variations?.length || 0} variant &nbsp; Stock: {stock}
        </Typography>
        <Typography variant='h5' sx={{ mb: 1.5 }} color="text.primary" fontWeight='bolder'>
          {convertPrice(product.price || '0')}
        </Typography>
        <Typography variant="body2">
          {product.description || '-'}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" variant='outlined' onClick={_ => onPush(`/product/${product.product_id}`)}>Pilih</Button>

        <Button onClick={_ => onOrder(product.product_id)} color='secondary' variant='outlined' size='small' endIcon={<ShoppingCart size={25}/>}>
          Pesan
        </Button>
      </CardActions>
    </Card>
  );
}
