import {
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  Typography,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  Stack,
  Modal,
  Box,
  IconButton,
  Button,
} from '@mui/material';
import {
  useLocation,
} from 'react-router-dom';
import {
  SearchOutlined,
  DeleteForeverOutlined,
  Web,
  WhatsApp,
} from '@mui/icons-material';
import {
  Helmet
} from 'react-helmet-async';
import {
  convertPrice,
  getQueryUrl,
} from '../helpers/custom';


export const SetOrder = ({
  product,
  onClose = _ => null,
}) => {

  const {state} = useLocation();

  function onOrder (type = 'web') {
    let _link = '',
      admin_phone_number = '6289625091994';
    if(type === 'web') {
      const stateUrl = state?.url;
      let _category = 'all';
      if(stateUrl) {
        try {
          const _query = getQueryUrl(stateUrl);
          _category = _query.category || 'all';
        }catch(e) {}
      }
      _link = `https://my.jajanelona.com/${_category}/${product?.product_id || product?.parent_id}`;
    }else 
      _link = `https://wa.me/send?phone=${admin_phone_number}&text=Hello Admin Elona,
Saya mau Pesan Produk yg ada di Website

${product.name} ${convertPrice(product.price || '0')}`;


    const a = document.createElement('a');
    a.setAttribute('target', '_blank');
    a.setAttribute('href', _link);
    a.click();
  }


  return (
    <ElonaModal
      onClose={onClose}
      open
      title='Pilih Cara Order'
      boxStyle={{width: '35%', height: '45%', overflow: 'auto'}}>
      <StackRowList
        sx={{mt: 5}}
        justifyContent='space-around'
        alignItems='center'
        flexDirection='row'>
        <Button
          size='small'
          color='primary'
          onClick={_ => onOrder('web')}
          endIcon={<Web/>}>
          Website (Prio)
        </Button>
        <Button
          size='small'
          color='primary'
          onClick={_ => onOrder('whatsapp')}
          endIcon={<WhatsApp/>}>
          Whatsapp
        </Button>
      </StackRowList>
    </ElonaModal>
  )
}


export const Seo = ({title, description, type = 'webapp', name = 'dcar developer', link = {rel: 'canonical', href: '/'}}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <link
        rel={link.rel}
        href={link.href}/>
    </Helmet>
  );
};
export const Title = ({label, sx = {}, ...props}) => (

  <Typography
    variant="h5"
    noWrap
    component="a"
    sx={{
      flexGrow: 1,
      fontFamily: 'monospace',
      fontWeight: 700,
      letterSpacing: {
        xs: '.1.5rem',
        md: '.3rem',
      },
      color: 'inherit',
      textDecoration: 'none',
      ...sx,
    }}
    {...props}
  >
    {label}
  </Typography>
)

export const StackRowList = ({
  children,
  ...props
}) => (
  <Stack
    width='100%'
    display='flex'
    flexDirection='row'
    alignItems='center'
    justifyContent='space-between'
    {...props}>
    {children}
  </Stack>
)


export const SearchInput = ({
  title = '',
  changeState = value => null,
  fullWidth = false,
  sx={},
  valueState = '',
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(valueState);
  }, [valueState])

  return (
    <FormControl sx={{width: fullWidth ? '100%' : undefined, ...sx}} variant='standard'>
      <InputLabel>{title}</InputLabel>
      <Input
        size='small'
        type='text'
        value={value}
        onKeyUp={({key}) => {
          if(key === 'Enter')
            changeState(value);
        }}
        onChange={({target: {value}}) => setValue(value)}
        endAdornment={
          <InputAdornment position='end'>
            {valueState && value && value === valueState
              ? <IconButton
                  size='small'
                  onClick={_ => changeState('')}>
                  <DeleteForeverOutlined color='error'/>
                </IconButton>
              : <IconButton
                  size='small'
                  onClick={_ => changeState(value)}>
                  <SearchOutlined/>
                </IconButton>}
          </InputAdornment>
        }/>
    </FormControl>
  )
}



export const ElonaContainer = ({
  children,
}) => (
  <StackRowList
    alignItems='center'
    justifyContent='flex-start'
    flexDirection='column'>
    
    <StackRowList
      width={{
        md: '85%',
        xs: '95%',
      }}
      justifyContent='center'
      flexDirection='column'
      sx={{
        backgroundColor: '#ffffee',
        p: {
          md: 2,
          xs: 0.5,
        }
      }}>
      {children}
      </StackRowList>
  </StackRowList>
)


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: {
    xs: '79%',
    md: '50%',
  },
  maxWidth: '90%',
  maxHeight: {
    md: '90%',
    xs: '80%',
  },
  bgcolor: 'background.paper',
  border: '2px solid #000',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxShadow: 24,
  p: 4,
};

export const ElonaModal = ({
  open = false,
  boxStyle = {},
  title = '',
  mainContainer = {},
  onClose = _ => null,
  children,
}) => ((
  <Modal
    open={open}
    onClose={_ => onClose(false)}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={{...style, ...boxStyle}}>
      <Typography id="modal-modal-title" variant="h6" component="div">
        {title}
      </Typography>
      <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'flex-start',...mainContainer}}>
        {children}
      </div>
    </Box>
  </Modal>
))


export const ModifierItem = ({index, modifier, onChangeModifier = (modifier_id, option_id, payload, only_one) => null, limit_modifier = 5}) => {
  const disable_item = useMemo(() => {
    return limit_modifier === modifier.options?.filter(el => el.isChecked).length && modifier.has_limit;
  }, [limit_modifier, modifier]);

  const ButtonFunc = ({option}) =>
    <Button
      disabled={disable_item && !option.isChecked}
      variant='outlined'
      color='secondary' size='small'
      sx={{
        m: 0.5,
        color: option.default || option.isChecked ? 'green' : '',
        borderColor: option.default || option.isChecked ? 'green' : '',
      }}
      key={index}
      onClick={_ => onChangeModifier(modifier._id, option._id, {isChecked: option.isChecked ? false : true}, modifier.only_one)}>
      {option.name || '-'} {option.price ? <><br/> {convertPrice(option.price)}</> : null}
    </Button>
  return (
    <Stack
      display='flex'
      flexDirection='column'
      sx={{
        mt: 1,
      }}>
      <Typography variant='subtitle2'>{modifier.identity || '-'} {modifier.has_limit ? <>&nbsp; Limit: {limit_modifier} item</> : null}</Typography>

      {modifier.options?.length > 0
        ? <Stack
            width='100%'
            display='flex'
            flexDirection='row'
            flexWrap='wrap'>
            {modifier.options.map((option, index) => (
              <ButtonFunc key={index} option={option}/>
            ))}
          </Stack>
        : null}
    </Stack>
  )
}