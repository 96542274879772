import {
  useEffect,
} from 'react';
import {
  Alert,
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import {
  ElonaModal,
} from './ui';
import {
  useListCategories,
} from '../services/product';
import {
  color,
} from '../env';

export const CategoryModal = ({
  currentCategory = '',
  onSelect = category => null,
  ...props
}) => {
  const {
    onProcess,
    data,
    error,
    loading,
  } = useListCategories();

  useEffect(() => {
    onProcess();
  }, [onProcess]);

  return (
    <ElonaModal
      title='Pilih Kategori'
      {...props}
      mainContainer={{
        marginTop: 12,
        alignItems: 'center',
        justifyContent: 'center',
      }}
      boxStyle={{
        minHeight: '35%',
        bgcolor: color.mainColor,
      }}>
      
      {loading
        ? <CircularProgress size={35}/>
        : data?.categories.length > 0
          ? <Box sx={{width: '100%'}}>
              <List sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <ListItem
                  sx={{width: '85%', mt: 0.5, bgcolor: color.bgcolor}}>
                  <ListItemButton onClick={_ => onSelect('all')}>
                    <ListItemText sx={{
                      textTransform: 'capitalize',
                      fontWeight: 'bolder',
                    }}>
                      Semua Kategori
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
                {data.categories.map((category, index) => (
                  <ListItem
                    sx={{width: '85%', opacity: currentCategory && currentCategory === category.name ? 0.5 : 1, mt: 0.5, bgcolor: color.bgcolor}}
                    key={category?._id || index}>
                    <ListItemButton onClick={_ => onSelect(category.name)}>
                      <ListItemText sx={{
                        textTransform: 'capitalize',
                        fontWeight: 'bolder',
                      }}>
                        {category?.name || '-'}
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          : error
            ? <Alert severity='error'>{error}</Alert>
            : 'Belum ada kategori'}
    </ElonaModal>
  )
}