import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from 'react-router-dom';
import {
  Fragment,
} from 'react';
import {
  Typography,
  styled,
} from '@mui/material';
import {
  StackRowList,
} from './components/ui';
import {
  color,
} from './env';
import Header from './components/headers';
import HomePage from './containers/home';
import ProductPage from './containers/product/main';
import ProductDetailPage from './containers/product/detail';

// import SoonPage from './containers/soon';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

const Layout = () => {

  return (
    <Fragment>
      <Header/>
      {/* untuk space */}
      <Offset/>
      <Outlet/>


      {/* // FOOTER */}

      <StackRowList
        width={{
          md: '90%',
          xs: '100%',
        }}
        justifyContent='center'
        flexDirection='column'
        sx={{
          mt: 1,
          backgroundColor: color.bgcolor,
        }}>
        <Typography variant='body1'>
          Saran / Ide bisa email ke support@jajanelona.com
        </Typography>
      </StackRowList>
    </Fragment>
  )
}

const App = _ => {

  return (
    <Router>
      <Routes>
        <Route
          path='/'
          element={<Layout/>}>
          <Route
            path=''
            element={<HomePage/>}/>

          <Route
            path='/product'
            element={<Outlet/>}>
            <Route
              path=''
              element={<ProductPage/>}/>
            <Route
              path=':product_id'
              element={<ProductDetailPage/>}/>
            {/* <Route
              path=''
              element={<SoonPage/>}/>
            <Route
              path=':product_id'
              element={<SoonPage/>}/> */}
          </Route>
        </Route>
      </Routes>
    </Router>
  )
}

export default App;