
import {
  useEffect,
  Fragment,
  useMemo,
  useState,
  useCallback,
} from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  Alert,
  CardMedia,
  Stack,
} from '@mui/material';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  useGetProductId,
} from '../../services/product';
import {
  convertPrice,
} from '../../helpers/custom';
import {
  ElonaContainer,
  ModifierItem,
  StackRowList,
  SetOrder,
  Seo,
} from '../../components/ui';
import {
  ArrowLeft,
} from '@mui/icons-material';


function ProductIdPage () {
  const {onProcess, loading, data, res} = useGetProductId(),
    [product, setProduct] = useState(null),
    [modifiers, setModifiers] = useState([]),
    [placeOrder, setPlaceOrder] = useState(null),
    push = useNavigate(),
    {state} = useLocation(),
    modifiersSelected = useMemo(() => {
      let selections = [];
      for(const modifier of modifiers) {
        let modifierOption = modifier.options.filter(el => el.isChecked || el.default);

        if(modifierOption.length > 0) {
          selections.push({
            parent_id: modifier._id,
            name: modifier.identity,
            options: modifierOption.map(option => ({
              option_id: option._id,
              name: option.name,
              price: option.price,
              description: option.description || '',
            }))
          })
        }
      }
      return selections;
    }, [modifiers]),
    estimatePricee = useMemo(() => {
      if(!product)
        return 0;
      if(modifiersSelected.length === 0)
        return product.price * 1
      const modifierAmount = (() => {
        try {
          let count = 0;
          for(const modifier of modifiersSelected) {
            for(const option  of modifier.options)
              count += option.price;
          }

          return count;
        }catch(e) {}
        return 0;
      })()
      return product.price + modifierAmount;
    }, [modifiersSelected, product])


  function onChangeModifier (modifier_id, option_id, payload, only_one) {
    setModifiers(prev => prev.map(el => {
      if(el._id === modifier_id) {
        if(only_one)
          return {...el, options: el.options.map(modf => {
            if(modf._id === option_id)
              return {...modf, ...payload};

            return {...modf, isChecked: false, default: false};
          })}
        let tempModifier = {...el},
          getOptionIndex = tempModifier.options?.findIndex(mod => mod._id === option_id);

        if(getOptionIndex !== -1)
          tempModifier.options[getOptionIndex] = {...tempModifier.options[getOptionIndex], ...payload}


        return tempModifier;
      }

      return el;
    }))
  }

  const backDefault = useCallback(async () => {

    if(data && data.is_variation && data.variations.length > 0) {
      let variations = data.variations.sort((a, b) => b.stock - a.stock);
      setProduct({...variations[0], is_variation: true, promo: data?.promo})
    }
    else
      setProduct(data)

    if(data && data.modifiers && data.modifiers.length > 0)
      setModifiers(data.modifiers);
  }, [data])

  useEffect(() => {
    onProcess();
  }, [onProcess])

  useEffect(() => {
    backDefault()
  }, [backDefault]);

  return (
    <ElonaContainer>
      <Stack
        sx={{mt: 2}}/>

      <StackRowList
        sx={{
          mt: 1,
          mb: 1.5,
        }}>
        <Button
          size='small'
          variant='outlined'
          startIcon={<ArrowLeft/>}
          onClick={_ => push(state && state.url ? state.url : '/product')}>
          Kembali
        </Button>
      </StackRowList>
      {placeOrder
        ? <SetOrder
            onClose={_ => setPlaceOrder(null)}
            product={placeOrder}/>
        : null}
      {loading
        ? <CircularProgress size={35}/>
        : data && data.product_id
          ? <Grid display='flex' flexDirection='column' sx={{width: '100%', p: 1}}>
              <Seo
                title={`Produk ${data.name}`}
                description={data.description || `${data.name} Fresh everyday, jajanan Palembang`}/>
              <CardMedia
                title={data.name}
                sx={{
                  height: {
                    xs: 300,
                    md: 500,
                  },
                  mb: 0.8,
                  mt: 0.8,
                  objectFit: 'fill',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                }}
                image={product?.images?.length > 0 ? product.images[0] : '/images/default'}/>
              <Typography variant='body1'>
                {data?.name || '-'}
                {product && product._id
                  ? <Fragment>
                    &nbsp;
                    {product.is_variation && product.name ? `- ${product.name}` : '-'} &nbsp; {convertPrice(product.price || '0')}
                  </Fragment>
                  : null}
              </Typography>
              <Stack
                width='100%'
                flexDirection='column'
                justifyContent='flex-start'
                alignItems='flex-start'
                sx={{
                  height: 350,
                  overflowY: 'auto',
                  overflowX: 'hidden',
                }}>
                {data?.variations?.length > 0
                  ? <Fragment>
                      <hr style={{width: '100%'}}/>
                      <Typography variant='h6'>-Variasi-</Typography>
                      <Stack
                        width='100%'
                        display='flex'
                        flexDirection='row'
                        flexWrap='wrap'
                        alignItems='center'
                        sx={{
                          mt: 1,
                          mb: 1.2,
                        }}>
                        {data.variations.map((variant, index) => (
                          <Button disabled={variant.variation_id === product?.variation_id} onClick={_ => setProduct({...variant, is_variation: true})} variant='outlined' size='small' key={index} sx={{minWidth: 150, ml: index !== 0 ? 0.5 : 0, mt: 1}}>
                            {variant?.name || '-'} - {variant?.stock || 0}<br/>
                            {convertPrice(variant.price || '0', true)}
                          </Button>
                        ))}
                      </Stack>
                    </Fragment>
                  : null}

                {modifiers.length > 0
                  ? <Fragment>
                      <hr style={{width: '100%'}}/>

                      <Typography variant='h6'>-Modifier-</Typography>
                      <Stack
                        width='100%'
                        flexDirection='column'
                        alignItems='flex-start'
                        sx={{
                          mt: 1,
                          mb: 1.5,
                        }}>
                        {modifiers.map((modifier, index) => <ModifierItem limit_modifier={product?.limit_option_modifier} onChangeModifier={onChangeModifier} key={index} index={index} modifier={modifier}/>)}
                      </Stack>
                      <hr style={{width: '100%'}}/>
                    </Fragment>
                    
                  : null}

              </Stack>

              <Typography variant='h6' fontWeight='bolder'>Stock: {product?.stock || 0}</Typography>
              
              <Typography variant='body1'>Total Harga</Typography>
              <Typography variant='h5' fontWeight='bolder'>{convertPrice(estimatePricee)}</Typography>
              <Typography variant='caption' color='green'>
                {product?.promo?._id && product?.promo?.is_active
                  ? product?.promo?.description || '-'
                  : null}
              </Typography>


              <Grid item display='flex' sx={{mt: 2}}>
                <Button size='small' variant='outlined' color='warning' onClick={_ => push('/product')}>Batal</Button>
                <Button size='small' sx={{ml: 1}} variant='outlined' color='info' onClick={_ => setPlaceOrder({...data, name: `${data.name} ${product.is_variation && product.name ? `- ${product.name}` : '-'}`, price: product?.price || data.price})}>Pesan</Button>
              </Grid>

              {res.status && res.message
                ? <Alert sx={{mt: 1}} severity={res.status === 'ok' ? 'success' : res.status}>{res.message}</Alert>
                : null}
            </Grid>
          : null}
    </ElonaContainer>
  );
}

export default ProductIdPage;